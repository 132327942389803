.footer-distributed{
    border-radius: 2px;
    background: linear-gradient(145deg, #bfcbcc, #e3f2f3);
    box-shadow:  5px 5px 7px #a1acad,
                 -5px -5px 7px #ffffff;
      box-sizing: border-box;
      width: 100%;
      text-align: left;
      font: bold 15px sans-serif;
      padding: 15px 25px;
  }
  
  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right{
      display: inline-block;
      vertical-align: top;
  }
  
  /* Footer left */
  
  .footer-distributed .footer-left{
      width: 40%;
  }
  
  /* The company logo */
  
  .footer-distributed h3{
      color:  #055157;
      font: normal 36px 'Open Sans', cursive;
      margin: 0;
  }
  
  .footer-distributed h3 span{
      color:  #9b4314;
  }
  
  /* Footer links */
  
  .footer-distributed .footer-links{
      color:  #055157;
      margin: 20px 0 12px;
      padding: 0;
  }
  
  .footer-distributed .footer-links a{
      display:inline-block;
      line-height: 1.8;
    font-weight:400;
      text-decoration: none;
      color:  inherit;
  }
  
  .footer-distributed .footer-company-name{
      color:  #9b4314;
      font-size: 14px;
      font-weight: normal;
      margin: 0;
  }
  .footer-links li {
    list-style: none;
  }
  
  /* Footer Center */
  
  .footer-distributed .footer-center{
      width: 35%;
  }
  
  .footer-distributed .footer-center i{
      /* background-color:  #0d0e0e; */
      color: #055157;
      font-size: 25px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      text-align: center;
      line-height: 42px;
      margin: 10px 15px;
      vertical-align: middle;
  }
  
  .footer-distributed .footer-center i.fa-envelope{
      font-size: 17px;
      line-height: 38px;
  }
  
  .footer-distributed .footer-center p{
      display: inline-block;
      color: #055157;
    font-weight:400;
      vertical-align: middle;
      margin:0;
  }
  
  .footer-distributed .footer-center p span{
      display:block;
      font-weight: normal;
      font-size:14px;
      line-height:2;
  }
  
  .footer-distributed .footer-center p a{
      color:  #9b4314;
      text-decoration: none;;
  }
  
  .footer-distributed .footer-links a:before {
    /* content: "|"; */
    font-weight:300;
    font-size: 20px;
    left: 0;
    color: #055157;
    display: inline-block;
    padding-right: 5px;
  }
  
  .footer-distributed .footer-links .link-1:before {
    content: none;
  }
  .footer-distributed .footer-links li:hover {
color:#ffffde
 }
  
  /* Footer Right */
  
  .footer-distributed .footer-right{
      width: 20%;
  }
  
  .footer-distributed .footer-company-about{
      line-height: 20px;
      color:  #131314;
      font-size: 13px;
      font-weight: normal;
      margin: 0;
  }
  
  .footer-distributed .footer-company-about span{
      display: block;
      color:  #055157;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 20px;
  }
  
  .footer-distributed .footer-icons{
      margin-top: 25px;
  }
  
  .footer-distributed .footer-icons a{
      display: inline-block;
      width: 35px;
      height: 35px;
      cursor: pointer;
      background-color:  transparent;
      border-radius: 2px;
  
      font-size: 20px;
      color: #9b4314;
      text-align: center;
      line-height: 35px;
  
      margin-right: 3px;
      margin-bottom: 5px;
  }
  
  /* If you don't want the footer to be responsive, remove these media queries */
  
  @media (max-width: 880px) {
  
      .footer-distributed{
          font: bold 14px sans-serif;
      }
  
      .footer-distributed .footer-left,
      .footer-distributed .footer-center,
      .footer-distributed .footer-right{
          display: block;
          width: 100%;
          margin-bottom: 40px;
          text-align: center;
      }
  
      .footer-distributed .footer-center i{
          margin-left: 0;
      }
  
  }