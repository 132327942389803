@import url('https://fonts.googleapis.com/css2?family=Andika:wght@700&family=Kanit:wght@200&family=Montserrat:wght@200;400;600&family=MuseoModerno:wght@200;400;600&family=Poppins:wght@200;400;600&display=swap');

html,
body,
#root,
.app {
  max-height: 100%;
  max-width: 110%;
  padding: 0.4rem;
  color: rgb(0, 0, 0);
  font-family: 'MuseoModerno', cursive;
  background-color: #e7edee; 
overflow-x: hidden;
  background: url('./images/bg-texture.jpg')  center center cover no-repeat fixed;
}
/* primary color #0c4b53  rgb(12,75,83) */
/* secondary color #9b4314  rgb(155,67,20)   */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #242427;
}

::-webkit-scrollbar-thumb {
  background: #48494e;
}

::-webkit-scrollbar-track:hover {
  background: #2a2b2f;
}


/* ---- cards ----- */

.quote-section, .quote-container {
  margin-top: 5rem;
    background: #0c4b53;
    background-image: url('../src/images/texture1.png');
    background-position: 50%;
    background-size: cover;
    border-radius: 1rem 0 1rem 1rem;
    flex-direction: column;
    height: 25vh;
    padding: 30px;
    width: 75%;
    align-items: center;
    display: flex;
    justify-content: center;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
    

}

.quote-text {
  font-size: 24px;
  color: #ffff;
}
.quote-author {
  color: #a7c734;
  font-style: italic;
}


.box {
  background: transparent;
  background-color: #0c4b53;
  border: 2px solid transparent;
  text-align: center;
  padding: 3rem 2rem 0 2rem;
  border-radius: 0 1rem 0 1rem;
  /* transition:cubic-bezier(0.075, 0.82, 0.165, 1); */
  -webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem rgb(61, 60, 60));
  filter: drop-shadow(0 2rem 3rem #222);

}

.box:hover {
  background: transparent;
  border-color: #0c4b53;
  cursor: default;
}

.box span {
  width: 3rem;
  height: 3rem;
  background: var(--color-primary);
  color: var(--color-grey-100);
  font-size: 1.5rem;
  padding: 0.8rem;
  display: grid;
  place-items: center;
  margin-bottom: 1.5rem;
  margin-inline: auto;
  border-radius: 1rem;
  transition: var(--transition);
}

.box:hover span {
  background: var(--color-secondary);
  color:var(--color-grey-600);

}
.box small {
  margin-top: 1rem;
  display: block;
  font-weight: 300;
}

.container {
  width: var(--container-width-lg);
  max-width: 1920px;
  margin-inline: auto;
}
h1,h2,h3, h4, h5 {
  line-height: 1.2;
  color:var(--color-grey-100);
}

h1 {
  font-size: 3rem;

}

h2 {
  font-size: 2rem;

}
a {
  color: var(--color-grey-100);
}

img {
  display: block;
  object-fit: cover;
  width:100%
}





section {
  margin-top: 9rem;
  

}
.section__head {
  display: flex;
  align-items: center;
  gap:1rem;

}

.section__head span {
  background: var(--color-grey-500);
  padding: 0.8rem;
  border-radius: 1rem;
  color: var(--color-secondary);
  font-size: 1.5rem; 
}