h1,
h2,
h3,
h4,
h5,
h6 {}
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}


.sec-title{
position:relative;
z-index: 1;
margin-bottom:60px;
}
.sec-title-goal {
    margin-top: 10rem;
}
.sec-title-goal .title{
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 24px;
    color: #9b4314;
    font-weight: 500;
    margin-bottom: 15px;
    }
    
    .sec-title-goal h2{
    position: relative;
    display: block;
    font-size:40px;
    line-height: 1.28em;
    color: #0c4b53;
    font-weight: 600;
    padding-bottom:18px;
    }
    

.sec-title .title{
position: relative;
display: block;
font-size: 18px;
line-height: 24px;
color: #9b4314;
font-weight: 500;
margin-bottom: 15px;
}

.sec-title h2{
position: relative;
display: block;
font-size:40px;
line-height: 1.28em;
color: #0c4b53;
font-weight: 600;
padding-bottom:18px;
}

.sec-title h2:before{
position:absolute;
/* content:''; */
left:0px;
bottom:0px;
width:50px;
height:3px;
background-color:#d1d2d6;
}

.sec-divider span {
width: 100%;
 font-size: 18px;
 color: #0c4b53;
}




.btn-style-one{
position: relative;
display: inline-block;
font-size: 17px;
line-height: 30px;
color: #ffffff;
padding: 10px 30px;
font-weight: 600;
overflow: hidden;
letter-spacing: 0.02em;
background-color: #0c4b53;
border-radius: 3rem;
}

.btn-style-one:hover {
background-color: #9b4314;
color: #ffffff;
}
.about-section {
position: relative;
padding: 12px 0 7px;
}

.about-section .sec-title {
margin-bottom: 45px;
}

.about-section .content-column {
position: relative;
margin-bottom: 50px;
}

.about-section .content-column .inner-column {
position: relative;
padding-left: 30px;
}

.about-section .text {
margin-bottom: 20px;
font-size: 16px;
line-height: 26px;
    color: rgb(13, 90, 92);font-weight: 400;
}

.about-section .list-style-one {
margin-bottom: 45px;
}



.about-section .btn-box a {
padding: 15px 50px;

}

.about-section .image-column {
position: relative;
padding-left: 2rem;
-webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem rgb(61, 60, 60));
filter: drop-shadow(0 2rem 3rem #222);
}



.about-section .image-column .inner-column {
position: relative;
padding-left: 80px;
padding-bottom: 0px;
}

.about-section .image-column .inner-column:before {
content: '';
position: absolute;
width: calc(50% + 80px);
height: calc(100% + 110px);
top: -80px;
left: -3px;
background: transparent;
z-index: 0;
border: 44px solid #0c4b53;

}

.about-section .image-column .image-1 {
position: relative;
}

.about-section .image-column .image-2 img,
.about-section .image-column .image-1 img {
box-shadow: 0 30px 50px rgba(8,13,62,.15);
border-radius: 46px;
}


.about-section .image-column .video-link .link{
position: relative;
display: block;
font-size: 22px;
color: #191e34;
font-weight: 400;
text-align: center;
height: 100px;
width: 100px;
line-height: 100px;
background-color: #ffffff;
border-radius: 50%;
box-shadow: 0 30px 50px rgba(8,13,62,.15);
-webkit-transition: all 300ms ease;
-moz-transition: all 300ms ease;
-ms-transition: all 300ms ease;
-o-transition: all 300ms ease;
transition: all 300ms ease;
}
.inner-column h2 span {
    color:#9b4314
}



/* ==========media ======== */

@media (max-width:688px) {
    .about-section .image-column .inner-column {
        display: none;
    }


}