

.main-header {
    margin-top: 2.5rem;
    padding: 2rem;
    position: a;
    display: flex;
    background: transparent;
    color: black;
}
/* body {
    background-image: linear-gradient(180deg, #ccc 50%, #fff 50%);
    background-repeat: repeat;
    height: 100vh;
  } */

  .border {
    background:url('../../images/header/mainheader.png');
    -webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem rgb(61, 60, 60));
    filter: drop-shadow(0 2rem 3rem #222);
    border-top: 16px solid #0c4b53;
    border-right: 16px solid #0c4b53;
    border-bottom: 16px solid #9b4314;
    border-left: 16px solid #9b4314;
    border-radius: 50%;
    margin: 0 auto;
    padding: 3em;
    width: 27em;
    height: 27em;
    position: relative;
  }

  .main-header-img {
    width: 25rem;
    height: 25rem;
    position: absolute;
    -webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem rgb(61, 60, 60));
    filter: drop-shadow(0 2rem 3rem #222);
  }

  .header-left h1 {
    font-size: 26px;
    color: #9b4314;

  }
  .header-left span {
    font-size: 28px;
    color: #0c4b53;

  }
  .header-left h4  {
    color: rgb(13, 90, 92);
  }
  .header-left p {
    font-size: 16px;
    color: rgb(13, 90, 92);


  }
@media (max-width:960px) {
  .border {
    background:url('../../images/header/mainheader.png');
    -webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem rgb(61, 60, 60));
    filter: drop-shadow(0 2rem 3rem #222);
    border-top: 16px solid #0c4b53;
    border-right: 16px solid #0c4b53;
    border-bottom: 16px solid #9b4314;
    border-left: 16px solid #9b4314;
    border-radius: 50%;
    margin: 0 auto;
    padding: 3em;
    width: 24em;
    height: 24em;
    position: relative;
  }
  
}


  @media (max-width:688px) {
    .main-header-img, .border {
display: none;   
 }
 .header-left p {
    font-size: 10px;
 }
 .header-left h1 {
    font-size: 20px;
 }
 .header-left span {
    font-size: 21px;
 }
 .header-left h4 {
    font-size: 10px;
 }


}