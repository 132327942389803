.contact-container {
    /* max-width: 1300px; */
    width: 100%;
    padding: 3px;
    margin: 0 auto;
    position: relative;
    background: transparent; /* Background color for the content area */
    padding: 25px; 
  }

  .contact-divider {
    width: 100%;
    margin-top: 5rem;
    font-size: 28px;
    color: #0c4b53;
    text-align: center;
  }

.contact__wrapper  {
    width: fit-content;
    display: flex;
    gap: 2rem;

}

.contact-container h2 {
    color: #0c4b53;
    font-size: 3rem;
    text-align: center;    
}
.contact-container p {
    color:#9b4314;
    text-align: center;
}


.contact__wrapper a {
    color: #9b4314;
    font-size: 2rem;
    border: 2px solid transparent;
    transition: var(--transition);
    padding: 2rem;
    border-radius: 1.5rem;
}

.contact__wrapper a:hover {
    background: transparent;
    border-color: #9b4314;
}
.contact-container h2 {
    margin-bottom: 3rem;
}

.address__wrapper {
    place-items: center;
    display: flex;
    gap: 2rem;
    background: #0c4b53;
    margin-top: 2rem;
    font-size: 1rem;
    border: 2px solid transparent;
    color: #ffff;
    padding: 2rem;
    border-radius: 1.5rem;
    margin-bottom: 3rem;
}
.address__wrapper h3 {
    margin-bottom: 1rem;
    color:#ffff
}
.address__wrapper p {
    margin-bottom: 1rem;
    color:#ffff;
    text-align: left;
}
.address__wrapper:hover {
    /* background: transparent; */
    border-color: #9b4314;
    color: #0c4b53;
}


/*  media  */
@media screen and (max-width:600px) {
    .contact__wrapper {
        gap: 1.5rem;
    }
    .contact__wrapper a {
        padding: 1rem;
        font-size: 1.2rem;
        border-radius: 1rem;
    }
    .address__wrapper {
        padding: 1rem;
        font-size: 1.2rem;
        border-radius: 1rem;
    }
    
}

input[type=text], select, textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
  }
  
  /* Style the submit button with a specific background color etc */
  input[type=submit] {
    background-color:#0c4b53;
    color: #ffff;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* When moving the mouse over the submit button, add a darker green color */
  input[type=submit]:hover {
    border: 2px solid #9b4314;
    transition: var(--transition);
    padding: 1rem;
    border-radius: 0.5rem;
    background: #9b4314;
    border-color:#fff;
  }
  
  /* Add a background color and some padding around the form */

  .map_sec{
    display: flex;
    position: relative;
    align-self:center;

}
.map_inner h4 {
    color: #0c4b53;
    text-align: center;
    font-size: 22px;

}
.map_inner p{
    font-size: 16px;
    color: #9b4314;
    text-align: center
}
.map_bind{
   margin-top: 50px;
    border-radius: 30px;
    /* overflow: hidden; */
}

  @media(max-width: 1024px){
    .contact_inner {
        background: transparent;
        position: relative;
        box-shadow: 20px 22px 44px #cccc;
        border-radius: 5px;
    }
    .contact_field {
position: relative;
    }

}
