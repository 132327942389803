.section-container {
    margin-top: 9rem;
    text-align: center;
}

.section-container h2 {
    color:#9b4314

}
.section-container p {
    color: rgb(13, 90, 92);

}

.columns {
    display: flex;
}

.section-left  {
    width: calc((100%-4rem)/3);
    padding: 0;
    margin-top: 0rem;
}

.section-left li {
    margin-top: 2rem;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.section-left ul {
    margin-top: 0rem;
    list-style: none;
    padding: 0;


  }
  .section-divider span {
    width: 100%;
 margin-top: 5rem;
 font-size: 28px;
 color: #0c4b53;
}


.section-img {
    position: relative;
    margin-top: 12rem;
    -webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem rgb(61, 60, 60));
    filter: drop-shadow(0 2rem 3rem #222);
}

.section-left h6 {
    font-size: 20px;
    color: #0c4b53;}

.section-left p {
    font-size: 14px;
    color: rgb(13, 90, 92);
}

.list span i {
    content: '*';

}
