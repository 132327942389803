.testimonials-divider {
    width: 100%;
    margin-top: 5rem;
    font-size: 28px;
    color: #0c4b53;

}
.testimonials__head {
    justify-content: center;
    text-align: center;


}

.testimonial {
    position: relative;
    text-align: left;
    margin-top: 5rem;
    background-color:#e7eded;
    border-radius: 2.5rem;
    -webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem rgb(61, 60, 60));
    filter: drop-shadow(0 2rem 3rem #222);


}
.testimonial__avatar {
    width: 8rem;
    height: 8rem;
    background-color:#e7eded;
    border-radius: 2.5rem;
    border: 2px solid transparent;
    overflow: hidden;
    position: relative;
    top: -3rem;
    left: calc(50% - 2rem);
    box-shadow: 0 1.5rem 2rem rgba(26,26,54,0.4);
    /* transition: var(--transition); */


}
.testimonial:hover .testimonial__avatar {
    border-radius: 50%;
    /* border-color: var(--color-grey-400); */
}

.testimonial__quote {
    font-style: italic;
    margin: 1rem 0 2rem;
}
 .testimonial__title {
    margin-top: 0.3rem;

}

.testimonials__btn-container {
    width: fit-content;
    margin: 1.5rem auto 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.testimonials__btn {
    /* background: transparent; */
    cursor: pointer;
}
.testimonials__btn svg {
    fill:#9b4314;
    font-size: 1.8rem;
    width: 2rem;
    height: 2rem;

}

@media (max-width:688px) {
.testimonials__btn-container {
    width: 65%;
}
.testimonials__head {
    justify-content: center;
    text-align: center;
}

}