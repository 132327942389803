/* Common styles for all screen sizes */


.bg-img {
  margin-top: 3rem;
  margin-right: 3px;
  padding-right: 2rem;
  height: 17rem;
  border-radius: 3rem;
display: block;
position:absolute;
opacity: 55%;
overflow-x: hidden;
/* border: 4px solid  #0c4b53 ; */
}

.container {
  max-width: 1300px;
  width: 100%;
  padding: 3px;
  margin: 0 auto;
  position: relative;
  background: transparent; /* Background color for the content area */
  padding: 20px; /* Add padding to the content area */
}

.container h2 {
  color: #0c4b53;
  font-size: 3rem;
  text-align: center;
}
.container p {
  margin-bottom: 3rem;
  color: #9b4314;
  font-size: 23px;
  text-align: center;
}

.form-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto;
  max-width: 600px;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* ============= job list ================= */
.job-list {
  max-width: 1300px;
  width: 100%;
  padding: 3rem;
  margin-top: 5rem;
  position: relative;
  display: block;
  border: none;
 
}
.job-list p {
  color: #9b4314;
  /* margin-top: 2rem; */
  /* padding: 2rem; */
  position: absolute;
  font-size: 12px;
  margin-left: 12rem;
  /* background: rgb(45, 64, 87);
  background-clip: padding-box;
  border: 1px solid #ddd; */
  border-radius: 2px;


}
.job-list h4 {
  color:#0c4b53;
  margin-top: 1.5rem;
position: absolute;
margin-left: 12rem;

}
.job-list span {
  color:#0c4b53;
  font-size: 10px;
  margin-top: 4rem;
  padding: 2rem;
}

.job-list img {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  display: flex;
}
.nojob {
  max-width: 1300px;
  width: 100%;
  padding: 3rem;
  margin-top: 5rem;
  position: relative;
  display: block;
  border: none;
  text-align: center;
}
 .nojob p {
  color:#0c4b53;
  font-size: large;

 }

.btn-popup {
  padding: 8px 16px;
  background-color: #0c4b53;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.btn-popup:hover {
  background: #9b4314;
  color: #fff;
}

.job-item  {
  color: #9b4314;
  border: 1px solid #ddd;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  text-align: left;
  border: 2px solid #9b4314;
  display: flex;

}
.desc {
  position: relative;
  display: flex;
  width: auto;
  height: auto;
}

.input {
  width: 100%;
  max-width: 300px;
  padding: 8px;
  margin: 5px;
  border: 1px solid #0c4b53;
  border-radius: 4px;
}

.button {
  padding: 10px 20px;
  background-color: #0c4b53;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
.button:hover {
  background: #9b4314;
  color: #fff;
}

/* Responsive styles for different screen sizes */
@media screen and (max-width: 768px) {
  .landing19-head,
  .landing19-subhead {
    font-size: 24px;
  }
  
  .input,
  .button {
    max-width: 100%;
  }
  .job-list {
    width: 100%;
    padding: 1rem;
    margin-top: 3rem;
    position: relative;
    display: block;
    border: none;
   
  }
  .job-list p {
    color: #9b4314;
    margin-top: 0;
    /* padding: 2rem; */
    position: absolute;
    font-size: 10px;
    margin-left: 10rem;
    display: block;
  
  
  }
  .job-list h4 {
    color:#0c4b53;
    margin-top: 1rem;
  position: absolute;
  margin-left: 10rem;
  
  }
  .job-list span {
    color:#0c4b53;
    font-size: 9px;
    margin-top: 4rem;
    /* padding: 2rem; */
    text-align: left;
    display: inline-block;
  }
  
  .job-list img {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    /* display: flex; */
  }
  .btn-popup {
    padding: 6px 12px;
    background-color: #0c4b53;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    position: absolute;
  }
  
  .btn-popup:hover {
    background: #9b4314;
    color: #fff;
  }
  .desc {
    position: relative;
    display: flex;
    width: auto;
    height: auto;
    text-align: justify;
  }
  .nojob {
    max-width: 1300px;
    width: 100%;
    padding: 3rem;
    margin-top: 5rem;
    position: relative;
    display: block;
    border: none;
  }
   .nojob p {
    color:#0c4b53;
    font-size: large;
   }

}
