* {
    box-sizing: border-box;
    justify-content: space-between;
  }
  
  body {
    margin: 0px;
    font-family: 'segoe ui';

  }
  
  .nav {
    height: 95px;
    width: 100%;
    background-color: #d4e2e3;
    position: relative;
    border-radius: 5px;
    border-radius: 2px;
    background: linear-gradient(145deg, #bfcbcc, #e3f2f3);
    box-shadow:  20px 20px 17px #a7b3b3,
                 -20px -20px 17px #ffffff;
             
  }
  .nav-img {
    width: 70px;
    height: 80px;
    margin: 0.25rem 0.5rem 0;
    display: flex;
  }

  
   .nav-header {
    display: flex;
  }

   .nav-toggle-btn {
    display: none;
  }
  
   .nav-links li {
    display: inline;
    gap: 3.5rem;
    align-items: right;
    /* font-size: 13px; */
    list-style: none;



  }
  
 .nav-links  a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #252121;
    /* justify-content: space-between; */
    position: sticky;

  }
  
   .nav-links :hover {
    color:#9b4314;
  }
  
  /* .nav > #nav-check {
    display: none;
  } */
  .active-nav {
    position: relative;
}

  @media (max-width:1024px) {
    .nav-toggle-btn {
      padding: 2rem;
      display: inline-block;
      background: transparent;
      border: none;
      font-size: 1.8rem;
      cursor: pointer;
  }
  .nav-toggle-btn svg {
      color:#9b4314;

  }
  .nav-links {
      position: absolute;
      top:100%;
      right: 0;
      flex-direction: column;
      gap:0;
      perspective:300px ;
      z-index: 2;

  }
  .active-nav, .active-nav::after {
      display: none;

  }
  .nav-links li {
      height: 4rem;
      width: 100%;
      box-shadow: -2rem 2rem 5 rem rgba(0,0,0,0.5);
      animation: navAnimation 600ms ease forwards;
      transform: rotateX(90deg);
      opacity: 0;
      transform-origin: top;


  }
  .nav__links li:nth-child(2) {
      animation-delay: 100ms;
  }
  .nav__links li:nth-child(3) {
      animation-delay: 200ms;
  }
  .nav__links li:nth-child(4) {
      animation-delay: 300ms;
  }
  .nav__links li:nth-child(5) {
      animation-delay: 400ms;
  }
  .nav__links li:nth-child(6) {
      animation-delay: 1s;
  }
  .nav__links li:nth-child(7) {
      animation-delay: 1.2s;
  }
  @keyframes navAnimation {
      to {
          transform: rotateX(0);
          opacity: 1;
      }
      
  }

  .nav-links li a {
      background-color: #d4e2e3;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1rem 5rem 1rem 3rem;

  }
  .show__nav {
      display: flex;
  }
  .hide__nav {
      display: none;
  }
}