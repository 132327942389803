/* =============== service section ============= */

.row {
    margin-top: -4rem;
    display: flex;
}

.btn {
    color:#ffff;
    width: fit-content;
    margin: 3rem auto;
    padding: 0.9rem 1.5rem;
    background: #0c4b53 ;
    border-radius: 1.5rem;
    transition: var(--transition);
  
  }
  .btn:hover {
    background:#9b4314;
    color:#fff;
  }
  
  .btn .lg {
    padding: 1.2rem 3rem;
    border-radius: 2rem;
    font-size: 1.1rem;
  
  }
  .btn.sm {
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
  
  }
  .btn.sm:hover {
    background: var(--color-grey-100);
  }

  .section_container {
    margin-top: 7rem;
    text-align: right;
    display: flex;
    
}
.section_header {
    width: calc((80%-4rem)/2);
    padding: 5rem;
    margin-left: 9rem;
}

.section_header h2 {
    color:#9b4314;
    text-align:left;

}
.section_header p {
    color: rgb(13, 90, 92);
    text-align: justify;
}
.section_header h2 span {
    color: #0c4b53;
    }
.section_container img {
    width: 25rem;
    height: 25rem;
    position: relative;
    -webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem rgb(61, 60, 60));
    filter: drop-shadow(0 2rem 3rem #222);
}


/* ====================media screen =================== */

@media (max-width:1024px) {
    .section_container {
        margin-top: 5rem;
        text-align: right;
        display: flex;
    }
    .section_header {
        padding: 2.5rem;
        margin-left: 3rem;
    }
    
    .section_header h2 {
        color:#9b4314;
        text-align:left;
    
    }
    .section_header h2 span {
    color: #0c4b53;
    }

    .section_header p {
        color: rgb(13, 90, 92);
        text-align: justify;
    }
    .section_container img {
        width: 20rem;
        height: 20rem;
        position: relative;
    }

    
}

@media (max-width:688px) {
    .section_container img {
    display: inline;
    }
    .section_container {
        margin-top: 7rem;
        text-align: center;
        display: block;
    }
}