.section-head {
    display: flex;
    align-items: center;
    gap:1rem;
    -webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem rgb(61, 60, 60));
    filter: drop-shadow(0 2rem 3rem #222);

}

.section-head span {
    background:0c4b53;
    padding: 0.8rem;
    border-radius: 1rem;
    color: var(--color-secondary);
    font-size: 1.5rem; 
}
