.clientSection-container {
  margin-top: 7rem;
  text-align: center;
  -webkit-filter: drop-shadow(0.5rem 0.5rem 0.5rem rgb(61, 60, 60));
  filter: drop-shadow(0 2rem 3rem #222);
}
.clientSection-container h2 {
  color:#9b4314;
}

/* LogoSlider.css */
.logo-slider-container {
  background: #0c4b53;
  background-image: url('../../images/texture1.png');
  background-position: 50%;
  background-size: cover;
  border-radius: 3rem 0 3rem 0;
  flex-direction: column;
  height: 30vh;
  /* padding: 30px; */
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  }
  
  .logo-slider-track {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .logo-slide {
    flex: 0 0  100%;
    padding:3px 2px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo-slide img {
width: 160px;
height: 90px;  }
  