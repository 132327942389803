section {
  padding: 60px 0;
  min-height: 100vh;
  
}
a, a:hover, a:focus, a:active {
  text-decoration: none;
  outline: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.bg-gray {
  background-color: transparent;
  margin-top: 7rem;
}
.site-heading {
  text-align: center;
}
.section-divider span {
  text-align: center;
  width: 100%;
margin-top: 5rem;
font-size: 28px;
color: #0c4b53;
}

.site-heading  h2 {
color: #0c4b53;
display: block;
font-weight: 700;
margin-bottom: 10px;
text-transform: uppercase;
text-align: center;
}

.site-heading h2 span {
color: #9b4314;
}

.site-heading h4 {
display: inline-block;
padding-bottom: 20px;
position: relative;
text-transform: capitalize;
z-index: 1;
color: rgb(13, 90, 92);
}


.site-heading {
margin-bottom: 60px;
overflow: hidden;
margin-top: -5px;
text-align: center;
display: block;
}

.ser-img {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  border: 1px solid #ffffff;
  transform: rotate(-0deg);
  
}

.item {
  margin: auto;
background: transparent none repeat scroll 0 0;
border-left: 2px solid #9b4314;
-moz-box-shadow: 0 0 10px #cccccc;
-webkit-box-shadow: 0 0 10px #cccccc;
-o-box-shadow: 0 0 10px #cccccc;
box-shadow: 0 0 10px #cccccc;
overflow: hidden;
width: 75%;
padding: 25px;
gap: 2rem;
position: relative;
z-index: 1;
border-radius: 2rem;
/* display: flex; */
color:#0c4b53;
grid-template-columns: 1fr 1fr 1fr;
}


.we-offer-area.text-center .item i {
-webkit-border-radius: 50%;
-moz-border-radius: 50%;
border-radius: 50%;
color: #ffffff;
font-size: 40px;
height: 80px;
line-height: 80px;
position: relative;
text-align: center;
width: 80px;
z-index: 1;
transition: all 0.35s ease-in-out;
-webkit-transition: all 0.35s ease-in-out;
-moz-transition: all 0.35s ease-in-out;
-ms-transition: all 0.35s ease-in-out;
-o-transition: all 0.35s ease-in-out;
margin-bottom: 25px;
}

.we-offer-area.text-center .item i::after {
border: 3px solid #9b4314;
-webkit-border-radius: 50%;
-moz-border-radius: 50%;
border-radius: 50%;
content: "";
height: 90px;
left: -8px;
position: absolute;
top: -8px;
width: 90px;
z-index: -1;
transition: all 0.35s ease-in-out;
-webkit-transition: all 0.35s ease-in-out;
-moz-transition: all 0.35s ease-in-out;
-ms-transition: all 0.35s ease-in-out;
-o-transition: all 0.35s ease-in-out;
}


.our-offer-carousel.center-active .owl-item:nth-child(2n) .item,
.we-offer-area.center-active .single-item:nth-child(2n) .item {
background: #0c4b53 none repeat scroll 0 0;
}

.our-offer-carousel.center-active .owl-item:nth-child(2n) .item i,
.our-offer-carousel.center-active .owl-item:nth-child(2n) .item h4,
.our-offer-carousel.center-active .owl-item:nth-child(2n) .item p,
.we-offer-area.center-active .single-item:nth-child(2n) .item i,
.we-offer-area.center-active .single-item:nth-child(2n) .item h4,
.we-offer-area.center-active .single-item:nth-child(2n) .item p {
color: #ffffff;
}

.we-offer-area .item i {
display: inline-block;
font-size: 60px;
margin-bottom: 20px;
}

.we-offer-area .item h4 {
font-size: 22px;
text-transform: capitalize;
}

.we-offer-area .item p {
margin-top: 0;
font-size: 13px;
}
.we-offer-area .item i,
.we-offer-area .item h4,
.we-offer-area .item p {
transition: all 0.35s ease-in-out;
-webkit-transition: all 0.35s ease-in-out;
-moz-transition: all 0.35s ease-in-out;
-ms-transition: all 0.35s ease-in-out;
-o-transition: all 0.35s ease-in-out;
}

.we-offer-area .item::after {
background: #0c4b53 none repeat scroll 0 0;
content: "";
height: 100%;
left: -100%;
position: absolute;
top: 0;
transition: all 0.35s ease-in-out;
-webkit-transition: all 0.35s ease-in-out;
-moz-transition: all 0.35s ease-in-out;
-ms-transition: all 0.35s ease-in-out;
-o-transition: all 0.35s ease-in-out;
width: 100%;
z-index: -1;
}

.we-offer-area .item:hover::after {
left: 0;
}

.we-offer-area .item:hover i,
.we-offer-area .item:hover h4,
.we-offer-area .item:hover p {
color: #ffffff !important;
}

.we-offer-area.text-center .item:hover i::after {
border-color: #ffffff !important;
}

.we-offer-area.text-center .item:hover i {
background:#9b4314 !important;
color: #0c4b53 !important;
}
.hero-img {
  background: url('../../images/pages/serviceImage.png');
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  padding: auto;
}

/* section{
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 70vh;
  width: 75vw;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 12px
} */
.image{
  background-color: #12192c;
  display: flex;
  border-radius: 12px 0 0 12px;
}
.image img{
height:50vh;
margin:50px auto
}
.content{
  background-color: #12192c;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 0  12px 12px 0;
  color: #fff;
}
.content h2{
  text-transform: uppercase;
  font-size: 36px;
  letter-spacing: 6px;
  opacity: 0.9;
}
.content span{
  height: 0.5px;
  width: 80px;
  background: #777;
  margin: 30px 0;
}
.content p{
  padding-bottom: 15px;
  font-weight: 300;
  opacity: 0.7;
  width: 60%;
  text-align: center;
  margin: 0 auto;
  line-height: 1.7;
  color:#ffffff
}
.links{
  margin: 15px 0;
}
.links li{
  border: 2px solid #4158D0;
  list-style: none;
  border-radius: 5px;
  padding: 10px 15px;
  width: 160px;
  text-align: center;
}
.links li a{
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}
.links li:hover{
  border-color: #C850C0;
}

.vertical-line{
  height: 30px;
  width: 3px;
  background: #C850C0;
  margin: 0 auto;
}
.icons{
  display: flex;
  padding: 15px 0;
}
.icons li{
  display: block;
  padding: 5px;
  margin: 5px;
}
.icons li i{
  font-size: 26px;
  opacity: 0.8;
}
.icons li i:hover{
  color: #C850C0;
  cursor: pointer;
}


/*****************/

@media(max-width: 900px){
  /* section{
      grid-template-columns: 1fr;
      width: 100%;
      border-radius: none;
  } */
  .image{
      height: 100vh;
      border-radius: none;
  }
  .content{
      height: 100vh;
      border-radius: none;
  }
  .content h2{
      font-size: 20px;
      margin-top: 50px;
  }
  .content span{
      margin: 20px 0;
  }
  .content p{
      font-size: 14px;
  }
  .links li a{
      font-size: 14px;
  }
  .links{
      margin: 5px 0;
  }
  .links li{
      padding: 6px 10px;
  }
  .icons li i{
      font-size: 15px;
  }
}
.credit{
  text-align: center;
  color: #000;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.credit a{
  text-decoration: none;
  color:#000;
  font-weight: bold;
} 




@media (max-width:680px) {
  .item {
      display: grid;
      grid-template-columns:1fr ;
  }
  .hero-img {
display: none;
  }
}