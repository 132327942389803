/* Popup.css */

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }
  
  .popup {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 400px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
    color: rgb(249, 245, 245);
  }
  
  h2 {
    margin-bottom: 25px;
    /* font-size: 22px; */
  }
  
  form {
    margin-top: 3px;
    display: flex;
    flex-direction: column;
  }
  
  input,
  textarea {
    padding: 8px;
    /* margin-bottom: px; */
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    background-color: #0c4b53;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
  }

  .submit-button:hover {
    background: #9b4314;
    color: #fff;
  }
  